require("@rails/ujs").start()
require("turbolinks").start()
require("channels");
require("jquery");
require("moment");
require("@joeattardi/emoji-button");
require("bootstrap");
require("bootstrap-slider");
require("bootstrap-select");
require("bootbox");

import Rails from '@rails/ujs';
import moment from 'moment';
import bootbox from 'bootbox';
import jquery from 'jquery';
import EmojiButton from '@joeattardi/emoji-button';

window.Rails = Rails;
window.moment = moment;
window.bootbox = bootbox;
window.jquery = jquery;
window.$ = jquery;
window.jQuery = jquery;

window.EmojiButton = EmojiButton;

require("tempusdominus-bootstrap-4");
require("bootstrap-confirmation2");

import 'jquery-ui/ui/effect.js';
import 'jquery-ui/ui/effects/effect-blind.js';
import 'jquery-ui/ui/effects/effect-bounce.js';
import 'jquery-ui/ui/effects/effect-clip.js';
import 'jquery-ui/ui/effects/effect-drop.js';
import 'jquery-ui/ui/effects/effect-explode.js';
import 'jquery-ui/ui/effects/effect-fade.js';
import 'jquery-ui/ui/effects/effect-fold.js';
import 'jquery-ui/ui/effects/effect-highlight.js';
import 'jquery-ui/ui/effects/effect-puff.js';
import 'jquery-ui/ui/effects/effect-pulsate.js';
import 'jquery-ui/ui/effects/effect-scale.js';
import 'jquery-ui/ui/effects/effect-shake.js';
import 'jquery-ui/ui/effects/effect-size.js';
import 'jquery-ui/ui/effects/effect-slide.js';
import 'jquery-ui/ui/effects/effect-transfer.js';
import 'jquery-ui/ui/widgets/progressbar.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'jquery-ui/ui/widgets/droppable.js';

import '../src/javascripts/jquery.ui.touch.min.js';
import '../src/javascripts/jquery.fileupload.js';

import 'moment/locale/es.js';
import 'moment/locale/it.js';
import 'moment/locale/de.js';

moment.updateLocale('en', {
  week : {
    dow : 1
  },
  longDateFormat : {
    L: "DD/MM/YYYY",
    l: "D/M/YYYY",
  }
});

import * as cowork from '../src/javascripts/cowork.js.erb';
window.cowork = cowork;

require.context('../images', true);

import "controllers"
