import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

  }

  toggleFeature() {
    var feature = event.target.dataset.feature;
    var enabled = event.target.dataset.enabled;
    var project = event.target.dataset.project;
    var locale = event.target.dataset.locale

    if(enabled == 'true') {
      enabled = false;
      $(event.target).addClass('fa-toggle-off');
      $(event.target).removeClass('fa-toggle-on');
    } else {
      enabled = true;
      $(event.target).addClass('fa-toggle-on');
      $(event.target).removeClass('fa-toggle-off');
    }
    event.target.dataset.enabled = enabled;
    $.ajax({url: '/'+locale+'/projects/'+project+'/feature/'+feature+'/'+enabled.toString()});
  }

  restoreFile() {
    var fhash = event.target.dataset.fhash;
    var locale = event.target.dataset.locale;

    bootbox.dialog({
      message: event.target.dataset.confirmation,
      title: event.target.dataset.restore,
      onEscape: true,
      buttons: {
        cancel: {
          label: event.target.dataset.btn_cancel,
          className: "btn-outline-secondary",
        },
        create: {
          label: event.target.dataset.btn_restore,
          className: "btn-success",
          callback: function() {
            $.ajax(`/${locale}/files/${fhash}/restore`)
            .done(function() {
              $(`#deleted_file${fhash}`).fadeOut('100');
            })
            .always(function() {
              bootbox.hideAll();
            });
          }
        }
      }
    });
  }

  purgeFile() {
    var fhash = event.target.dataset.fhash;
    var locale = event.target.dataset.locale;

    bootbox.dialog({
      message: event.target.dataset.confirmation,
      title: event.target.dataset.purge,
      onEscape: true,
      buttons: {
        cancel: {
          label: event.target.dataset.btn_cancel,
          className: "btn-outline-secondary",
        },
        create: {
          label: event.target.dataset.btn_purge,
          className: "btn-danger",
          callback: function() {
            $.ajax(`/${locale}/files/${fhash}/purge`)
            .done(function() {
              $(`#deleted_file${fhash}`).fadeOut('100');
            })
            .always(function() {
              bootbox.hideAll();
            });
          }
        }
      }
    });
  }
}
