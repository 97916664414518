import jquery from 'jquery';
window.$ = jquery;

//$( document ).on('turbolinks:load', function() {
$( document ).ready(function() {
  window.addEventListener("keydown",function (e) {
    if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
      console.log("Search");
      if(!$('#dropdown-menu-search').hasClass("open")) {
        $('#ddSearch').dropdown('toggle');
      }
      setTimeout('$("#search").focus();$("#search").select()', 100);
      e.preventDefault();
    }
  });

  $('input#search').keyup(function(e) {
    if (e.keyCode === 27) {
      $('#ddSearch').dropdown('toggle');
    }
  });
});

$( document ).on('turbolinks:load', function() {
  $('#ddSearch').on('click', function(e) {
    setTimeout('$("#search").focus();$("#search").select()', 100);
  });

  $('#sidebarCollapse').on('click', function () {
    toggleSidebar();
  });

  $('#overlay').on('click', function () {
    toggleSidebar();
  });

  $('#sidebarDropdownMenu > a').on('click', function () {
    if($('#sidebar').hasClass('active')) {
      toggleSidebar();
    }
  });

  $('div.dropdown-menu a[data-remote=true]').click(function () {
    $(this).closest('div').prev('button').dropdown('toggle');
    $(this).closest('div').prev('a').dropdown('toggle');
  });

  /*
    Register Service worker
  */
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/serviceworker.js', { scope: '/' })
      .then(function(reg) {
        console.log('[Companion]', 'Service worker registered!');
      });
  }
});

$(document).ajaxSend(function(event, jqXHR, settings) {
  $('#loading').fadeIn(200);
});

$(document).ajaxComplete(function(event, jqXHR, settings) {
  $('#loading').fadeOut(200);
});

export function uploadFiles(files, project, folder) {
  for (var i = 0; i < files.length; i++) {
    let token = $( 'meta[name="csrf-token"]' ).attr( 'content' );
    let fd = new FormData();
    fd.append('dfile[label]', files[i].name);
    fd.append('dfile[description]', '');
    fd.append('dfile[phash]', project);
    fd.append('dfile[state]', 'final');
    fd.append('dfile[folder]', folder);
    fd.append('file', files[i]);

    $.ajax({
      type: "POST",
      url: '/files/upload',
      headers: {
        'X-CSRF-Token':token,
      },
      data: fd,
      processData: false,
      contentType: false
    })
    .done(function() {
      $("#info").fadeTo(8000, 500).slideUp(500);
    })
    .fail(function(event) {
      console.log( event );
    });
  }
}

export function registerCheckData() {
	var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  $('#loading').fadeIn();
	$('#btnSubmit').prop('disabled', true);
  $('#btnSubmit').removeClass('btn-success').addClass('btn-warning');

  var form = document.getElementById("register");
  if (form.checkValidity() === false || !$("#user_tos").is(":checked")) {
    form.classList.add('was-validated');
    $('#user_firstname').focus();
    $('#btnSubmit').prop('disabled', false);
    $('#btnSubmit').removeClass('btn-warning').addClass('btn-success');
    $('#loading').fadeOut();
    return false;
  } else {
    Rails.fire(form, 'submit');
  }
}

export function toggleSidebar() {
  $('#sidebar').toggleClass('active');
  $('#overlay').toggleClass('active');
}


/*
  WebPush Functions
*/

var vapidPublicKey = new Uint8Array([4, 48, 33, 85, 86, 188, 14, 121, 117, 137, 5, 125, 212, 2, 217, 143, 63, 92, 171, 143, 23, 13, 136, 138, 196, 140, 217, 124, 112, 195, 29, 194, 68, 208, 161, 178, 217, 113, 9, 92, 36, 27, 195, 134, 227, 71, 81, 198, 73, 10, 77, 199, 201, 29, 178, 210, 42, 27, 159, 95, 142, 102, 32, 138, 50]);

export function checkNotificationPermissions(obj){
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
  }
  else if (Notification.permission === "granted")
  {
    console.log("Permission to receive notifications has been granted");
    getNotificationKeys();
  }
  else if (Notification.permission !== 'denied')
  {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
        console.log("Permission to receive notifications has been granted");
        getNotificationKeys();
      }
    });
  }
}

export function getNotificationKeys(){
  navigator.serviceWorker.register('/serviceworker.js', {scope: '/'})
    .then(function(registration) {
      return registration.pushManager.getSubscription()
      .then(function(subscription) {
        if (subscription) {
          return subscription;
        }
        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey
        });
    });
  })
  .then(function(subscription) {
    cowork.sendNotificationKeys(subscription.toJSON())
  });
}

export function sendNotificationKeys(subscription){
  $.ajax({
    url: '/user/webpush/subscribe',
    type: 'post',
    data: JSON.stringify(subscription),
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticityToken(),
    },
    dataType: 'json',
    success: function (data) {
      console.info(data);
    }
  });
}

export function toggleFormSubmitButton(btn) {
  if(btn.prop('disabled')) {
    btn.html(btn[0].innerText)
    btn.removeClass('btn-warning');
    btn.addClass('btn-success');
    btn.prop('disabled', false);
  } else {
    btn.prop('disabled', true);
    btn.removeClass('btn-success');
    btn.addClass('btn-warning');
    btn.addClass()
    btn.prepend('<i class="fas fa-spinner fa-pulse"></i> ');
  }
}

function formHeaders() {
  return new Headers({
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': authenticityToken(),
  });
}

function authenticityToken() {
  return document.querySelector('meta[name=csrf-token]').content;
}
