import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "preview", "image", "title", "description"]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-url")) {
      this.url = this.element.getAttribute("data-url");
    }
  }

  connect() {
    this.getUrlPreview(this.url);
  }

  getUrlPreview(url) {
    var bookmarksController = this;
    Rails.ajax({
      url: `/${this.locale}/bookmark/preview?url=${this.url}`,
      type: "get",
      data: "",
      success: function(data) {
        if(data) {
          if(data.image != '') {
            bookmarksController.imageTarget.src = data.image;
          } else {
            bookmarksController.imageTarget.classList.add('d-none');
            bookmarksController.imageTarget.parentNode.innerHTML = "<center><i class='far fa-image fa-4x text-muted mt-3'></i></center>"
          }
          bookmarksController.titleTarget.innerHTML = data.title;
          bookmarksController.descriptionTarget.innerHTML = data.description;
          bookmarksController.previewTarget.classList.remove('d-none');
        }
      },
      error: function(data) {}
    });
  }

}
