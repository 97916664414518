import consumer from "./consumer"

consumer.subscriptions.create({ channel: "MessageChannel" }, {
  received(data) {
    notify(data);
  },

  connected() {
    console.log("Connected to cowork WebSocket");
  },

  disconnected() {
    console.log("Disconnected from cowork WebSocket");
  }
})

function notify(data) {
  //TODO: PopUp with last image
  if(data.unread > 0) {
    $("#unreadMessages").removeClass('d-none');
    $("#unreadMessages > a > span").html(data.unread);
    if(document.title.indexOf('(') >= 0) {
      document.title = document.title.replace(/\(.+?\)/, '('+data.unread+')');
    } else {
      document.title = '('+data.unread+') '+document.title;
    }
  } else {
    $("#unreadMessages").addClass('d-none');
    $("#unreadMessages > span").text('');
    if(document.title.indexOf('(') >= 0) {
      document.title = document.title.replace(/\(.+?\)/, '');
    }
  }

  if(data.conversation && data.conversation != '') {
    var page = document.location.href.split('/');
    if(page[page.length-1] == 'messages')
    {
      updateConversations();
      updateMessages(data);
    } else if(page[page.length-1] == 'start') {
      updateStartPage();
    } else {
      $("#unreadMessages").popover({
        content: `<div class="border-bottom mt-2 mb-2"><i class='far fa-comment'></i>&nbsp;<b>${data.sender}</div></b>${data.message}`,
        html: true,
        placement: 'bottom',
        animation: true
      });
      $("#unreadMessages").popover('show');
      setTimeout(function(){
        $("#unreadMessages").popover('hide');
        $("#unreadMessages").popover('dispose');
      }, 2000);
    }
  }
}

function updateConversations(data) {
  var url = window.location.protocol+'//'+window.location.host+'/'+window.location.pathname.split('/')[1]+'/messages/load';
  $.ajax({url: url});
}

function updateMessages(data) {
  if($("#currentConversation").text() == data.conversation) {
    var url = window.location.protocol+'//'+window.location.host+'/'+window.location.pathname.split('/')[1]+'/messages/reload?chash='+data.conversation;
    $.ajax({url: url});
  }
}

function updateStartPage() {
  var url = window.location.protocol+'//'+window.location.host+'/'+window.location.pathname.split('/')[1]+'/start/refreshmessages';
  $.ajax({url: url});
}
