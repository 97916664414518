import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["btn_toggle_fullscreen", "form", "i18n", "lbl_draft", "lbl_public"]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-backUrl")) {
      this.backUrl = this.element.getAttribute("data-backUrl");
    }
  }

  connect() {
    $('#blogpost_label').focus();
    $(".selectpicker").selectpicker({ width: '100%' });

    //Event on editor content change
    this.changes = false;
    var trix = document.querySelector("trix-editor");
    if(trix) {
      trix.editor.element.addEventListener('trix-change', () => {
        this.changes = true;
      });

      trix.addEventListener("trix-initialize", function(event) {
        let btnH1 = document.querySelector('.trix-button--icon-heading-1');
        let btnH2 = '<button type="button" data-trix-attribute="heading2" class="trix-button trix-button--icon-heading-2">H2</button>';
        let btnH3 = '<button type="button" data-trix-attribute="heading3" class="trix-button trix-button--icon-heading-3">H3</button>';
        btnH1.classList.remove('trix-button--icon');
        btnH1.innerText = 'H1';
        btnH1.insertAdjacentHTML("afterend", btnH3);
        btnH1.insertAdjacentHTML("afterend", btnH2);
      });
    }

    $('[data-toggle="comments-confirmation"]').confirmation({
      rootSelector: '[data-toggle=comments-confirmation]',
      onConfirm: function(){
        $.ajax({url: '/<%= I18n.locale %>/comments/blogpost/<%= @post.bhash %>/delete/'+$(this).data('chash')})
      }
    });

    document.querySelectorAll(".trix-content").forEach((div) => {
      div.querySelectorAll("a").forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    });

    this.changes = false;
    $("#loading").fadeOut('fast');
  }

  toggleFullScreen() {
    if(  $("#post_editor").css("position") == "absolute") {
      $("#post_editor").css("height", "calc(100vh - 64px)");
      $("#post_editor").css("min-height", 'auto');
      $("#post_editor").css("position", "static");
      $("#post_editor").css("z-index", "auto");
      $("#post_editor").addClass("p-3 p-md-3 p-lg-4").addClass("p-2");
      document.querySelector('trix-toolbar').style.top = "60px";
      document.querySelector('.blogpost-content trix-editor').style.minHeight = "auto";

      $(".details").slideDown('300');
      if($('input[name=shared]:checked', '#blogpost-new').val() == 'shared') {
        $("#share_with").slideDown('300');
      }
      this.btn_toggle_fullscreenTarget.classList.remove("btn-outline-success");
      $("#btnToggleFullscreen i").removeClass('fa-compress').addClass('fa-expand');
    } else {
      $("#post_editor").css("position", "absolute");
      $("#post_editor").css("top", 0);
      $("#post_editor").css("height", 'auto');
      $("#post_editor").css("min-height", '100vh');
      $("#post_editor").css("left", 0);
      $("#post_editor").css("z-index", 1049);
      $("#post_editor").removeClass("p-2 p-md-3 p-lg-4").addClass("p-3");
      document.querySelector('trix-toolbar').style.top = "0px";
      let trix = document.querySelector('.blogpost_content trix-editor');
      if(trix) {
        trix.style.minHeight = "calc(100vh - 250px)";
      }

      $(".details").slideUp('300');
      $("#share_with").slideUp('300');
      this.btn_toggle_fullscreenTarget.classList.add("btn-outline-success");
      $("#btnToggleFullscreen i").removeClass('fa-expand').addClass('fa-compress');
    }
  }

  toggleDraft() {
    if(event.target.checked) {
      this.lbl_publicTarget.classList.add('d-none');
      this.lbl_draftTarget.classList.remove('d-none');
    } else {
      this.lbl_draftTarget.classList.add('d-none');
      this.lbl_publicTarget.classList.remove('d-none');
    }
  }

  save() {
    var btn = $("button.btn-submit-close");
    cowork.toggleFormSubmitButton(btn);
    this.changes = false;
    Rails.fire(this.formTarget, 'submit');
  }

  checkChanges() {
    let i18n = this.i18nTarget;
    let backUrl = this.backUrl;
    if(this.changes) {
      bootbox.dialog({
        message: `<i class="fas fa-exclamation-circle"></i> ${i18n.dataset.cancel_confirm}`,
        buttons: {
          cancel: {
            label: i18n.dataset.btn_cancel,
            className: "btn-outline-secondary"
          },
          danger: {
            label: i18n.dataset.btn_ignore,
            className: "btn-outline-danger",
            callback: function() {
              window.location.href = backUrl;
            }
          }
        }
      });
    } else {
      window.location.href = this.backUrl;
    }
  }

  setPermissionsProject() {
    $("#share_with").hide('blind');
    $("#share_public").hide('blind');
  }

  setPermissionsShared() {
    $("#share_public").hide('blind');
    $("#share_with").show('blind');
  }

  setPermissionsPublic() {
    $("#share_with").hide('blind');
    $("#share_public").show('blind');
  }

}
