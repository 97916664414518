import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

  }

  animateCheckbox() {
    $(event.target).removeClass();
    $(event.target).addClass("fas fa-spinner fa-pulse");
  }
}
