import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

  }

  selectAttachment(event) {
    this.clearAttachments();
    var item = $(event.target).find('.paste-content');
    $('#message_attachment_type').val(item.data('type'));
    $('#message_attachment_hash').val(item.data('hash'));
    $("#attachments").html(item[0].innerHTML);
    $("#attachments").removeClass('d-none');
    box.modal('hide');
    quill.focus();

    var pos = quill.getLength();
    setTimeout(function() {
      quill.setSelection(pos, 0);
    }, 50);
  }

  clearAttachments() {
    $("#attachments").html('');
    $("#attachments").addClass('d-none');
    $("#videocall").html('');
    $("#videocall").addClass('d-none');
    $("#reply").html('');
    $("#reply").addClass('d-none');
    $("#btnClearAttachments").addClass('d-none');
    $("#message_reply").val('');
    $('#message_attachment_type').val('');
    $('#message_attachment_hash').val('');
  }

  startMessage() {
    $("#btns").fadeIn('fast');
    $("#msgtext").css('height', '160px');
  }

  sendMessage() {
    var form = document.querySelector('#message-new');
    var message = quill.root.innerHTML;
    $('#message_message').val(message);

    Rails.fire(form, 'submit');
  }

  replyToMessage(event) {
    this.clearAttachments();
    $("#btns").fadeIn('fast');

    var msg = $(event.target).closest('.message');
    var from = msg.find('.flex-fill').text().trim();
    var msg_content = msg.find('.message-content').html().trim();
    var timestamp = msg.find('.justify-content-end').text().trim();
    var reply = `<div class="d-flex"><div class="flex-fill"><cite class='semi-bold small'>${from} - ${timestamp}</cite>${msg_content}</div><button type="button" data-action="click->messages#clearAttachments" class="btn btn-link btn-sm text-secondary mt-0 mb-auto"><i class="fas fa-times-circle"></i></button>`;

    $("#reply").html(reply);
    $("#reply").removeClass('d-none');

    $("#message_reply").val(msg.data('message'));
    this.startMessage();

    quill.focus();

    var pos = quill.getLength();
    setTimeout(function() {
      quill.setSelection(pos, 0);
    }, 50);

  }

  cancelMessage() {
    this.clearAttachments();
    $("#btns").fadeOut('fast');
    quill.setText('');
    $("#msgtext").css('height', '32px');
  }

  startVideoCall() {
    let link = `<button class="btn btn-outline-secondary" disabled><i class="fas fa-video"></i>&nbsp;${event.target.dataset.link_text}</button>`
    $("#videocall").html(link);
    $("#videocall").removeClass('d-none');
    $('#message_attachment_type').val('videocall');
    $('#message_attachment_hash').val(this.generateVideoCallID());
    quill.focus();
  }

  generateVideoCallID() {
    var mask = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 16; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result;
  }
}
