import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = ["btn_toggle_fullscreen", "form", "i18n"]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-backUrl")) {
      this.backUrl = this.element.getAttribute("data-backUrl");
    }
  }

  connect() {
    $('#page_label').focus();
    $('.selectpicker').selectpicker({ width: '100%' });

    //Event on editor content change
    this.changes = false;
    var trix = document.querySelector("trix-editor");
    if(trix) {
      this.initPagesLink();

      trix.editor.element.addEventListener('trix-change', () => {
        this.changes = true;
      });

      trix.addEventListener("trix-initialize", function(event) {
        var buttonHTML = '<button type="button" id="pageLinkBtn" class="trix-button" data-action="click->pages#addPageLink"><i class="far fa-file fa-lg fa-fw pl-1 pr-1"></i></button>';
        event.target.toolbarElement.querySelector(".trix-button-group--file-tools").insertAdjacentHTML("beforeend", buttonHTML);

        let btnH1 = document.querySelector('.trix-button--icon-heading-1');
        let btnH2 = '<button type="button" data-trix-attribute="heading2" class="trix-button trix-button--icon-heading-2">H2</button>';
        let btnH3 = '<button type="button" data-trix-attribute="heading3" class="trix-button trix-button--icon-heading-3">H3</button>';
        btnH1.classList.remove('trix-button--icon');
        btnH1.innerText = 'H1';
        btnH1.insertAdjacentHTML("afterend", btnH3);
        btnH1.insertAdjacentHTML("afterend", btnH2);
      });
    }

    $('[data-toggle="comments-confirmation"]').confirmation({
      rootSelector: '[data-toggle=comments-confirmation]',
      onConfirm: function(){
        $.ajax({url: '/<%= I18n.locale %>/comments/page/<%= @page.pghash %>/delete/'+$(this).data('chash')})
      }
    });

    document.querySelectorAll(".trix-content").forEach((div) => {
      div.querySelectorAll("a").forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    });

    $("#loading").fadeOut('fast');
  }

  initPagesLink() {
    Rails.ajax({
      url: `/${this.locale}/page/links`,
      type: "get",
      data: "",
      success: function(data) {},
      error: function(data) {}
    });
  }

  toggleFullScreen() {
    if(  $("#page_editor").css("position") == "absolute") {
      $("#page_editor").css("height", "calc(100vh - 64px)");
      $("#page_editor").css("min-height", 'auto');
      $("#page_editor").css("position", "static");
      $("#page_editor").css("z-index", "auto");
      $("#page_editor").addClass("p-3 p-md-3 p-lg-4").addClass("p-2");
      document.querySelector('trix-toolbar').style.top = "60px";
      document.querySelector('.page_content trix-editor').style.minHeight = "auto";

      $(".details").slideDown('300');
      if($('input[name=shared]:checked', '#blogpost-new').val() == 'shared') {
        $("#share_with").slideDown('300');
      }
      this.btn_toggle_fullscreenTarget.classList.remove("btn-outline-success");
      $("#btnToggleFullscreen i").removeClass('fa-compress').addClass('fa-expand');
    } else {
      $("#page_editor").css("position", "absolute");
      $("#page_editor").css("top", 0);
      $("#page_editor").css("height", 'auto');
      $("#page_editor").css("min-height", '100vh');
      $("#page_editor").css("left", 0);
      $("#page_editor").css("z-index", 1049);
      $("#page_editor").removeClass("p-2 p-md-3 p-lg-4").addClass("p-3");
      document.querySelector('trix-toolbar').style.top = "0px";
      let trix = document.querySelector('.page_content trix-editor');
      if(trix) {
        trix.style.minHeight = "calc(100vh - 250px)";
      }

      $(".details").slideUp('300');
      $("#share_with").slideUp('300');
      this.btn_toggle_fullscreenTarget.classList.add("btn-outline-success");
      $("#btnToggleFullscreen i").removeClass('fa-expand').addClass('fa-compress');
    }
  }

  save() {
    var btn = $("button.btn-submit");
    cowork.toggleFormSubmitButton(btn);
    Rails.fire(this.formTarget, 'submit');
    cowork.toggleFormSubmitButton(btn);
    this.changes = false;
  }

  saveAndClose() {
    var btn = $("button.btn-submit-close");
    $('#close').val(true);
    cowork.toggleFormSubmitButton(btn);
    this.changes = false;
    Rails.fire(this.formTarget, 'submit');
  }

  checkChanges() {
    let i18n = this.i18nTarget;
    let backUrl = this.backUrl;
    if(this.changes) {
      bootbox.dialog({
        message: `<i class="fas fa-exclamation-circle"></i> ${i18n.dataset.cancel_confirm}`,
        buttons: {
          cancel: {
            label: i18n.dataset.btn_cancel,
            className: "btn-outline-secondary"
          },
          danger: {
            label: i18n.dataset.btn_ignore,
            className: "btn-outline-danger",
            callback: function() {
              window.location.href = backUrl;
            }
          }
        }
      });
    } else {
      window.location.href = this.backUrl;
    }
  }

  addPageLink() {
    console.log("click");
    let pagesController = this;
    $('#pageLinkBtn').popover('show');
    $('#page_link').selectpicker({ width: '250px' });

    var trix = document.querySelector("trix-editor");
    $('#page_link').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      setTimeout(function() {
        trix.editor.insertHTML(`<a href="/${pagesController.locale}/page/${e.target.value}/open">${e.target.selectedOptions[0].label}</a>`)
      }, 50);
      $('#pageLinkBtn').popover('hide');
    });
  }

  setPermissionsProject() {
    $("#share_with").hide('blind');
    $("#share_public").hide('blind');
  }

  setPermissionsPrivate() {
    $("#share_with").hide('blind');
    $("#share_public").hide('blind');
  }

  setPermissionsShared() {
    $("#share_public").hide('blind');
    $("#share_with").show('blind');
  }

  setPermissionsPublic() {
    $("#share_with").hide('blind');
    $("#share_public").show('blind');
  }

}
