import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

  }

  requestNotificationsPermission() {
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
    }
    else if (Notification.permission === "granted")
    {
      console.log("Permission to receive notifications has been granted");
      cowork.getNotificationKeys();
    }
    else
    {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          location.reload();
          console.log("Permission to receive notifications has been granted");
          cowork.getNotificationKeys();
        }
      });
    }
  }

}
